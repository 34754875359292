import React, { useState, useEffect } from 'react';
import MoreGamesModal from './MoreGamesModal';
import './themeToggle.css';

const MoreGamesButton = ({isDarkMode, showMoreGames}) => {
    const [isMoreGamesOpen, setIsMoreGamesOpen] = useState(showMoreGames);
    const toggleHowToPlay = () => {
        setIsMoreGamesOpen(!isMoreGamesOpen);
      };

    useEffect(() => {
        setIsMoreGamesOpen(showMoreGames)
    }, [showMoreGames]);

    return (
      <div>
        <button className={`mode ${isDarkMode ? 'dark' : 'light'}`} onClick={toggleHowToPlay}>
        <span className='toolbar-emoji'>🧩</span>
        <span className='toolbar-text'> More Games </span>
        </button>
        {isMoreGamesOpen && <MoreGamesModal onClose={toggleHowToPlay} isDarkMode={isDarkMode} />}
      </div>
    );
  }

export default MoreGamesButton;