import React from 'react';
import './Modal.css';

const MoreGamesModal = ({ onClose, isDarkMode }) => {
  const games = [
    { title: "Sqnces", url: "https://sqnces.com/", logo: "/images/Sqnces.png" },
    { title: "Quizmoji", url: "https://www.quizmoji.com/", logo: "/images/QuizMoji.png" },
    { title: "Stacked", url: "https://playstacked.web.app/", logo: "/images/Stacked.png" },
    { title: "Antonymz", url: "https://antonymz.web.app/", logo: "/images/Antonymz.png" },
    { title: "Puzzmallow", url: "https://puzzmallow.com/?utm_source=<yourpuzzle>", logo: "/images/Puzzmallow.png" },
    { title: "WordLock", url: "https://wordlockgame.com/", logo: "/images/WordLock.png" },
    { title: "Decodex", url: "https://playdecodex.com/?utm_source=", logo: "/images/Decodex.png" },
    { title: "Eightile", url: "https://eightile.com/", logo: "/images/Eightile.png" },
    { title: "Dodeku", url: "https://dodeku.com/?utm_source=", logo: "/images/Dodeku.png" }
  ];

  return (
    <div className="modal-overlay">
      <div className="modal-content left">
        <button className="close-button" onClick={onClose} aria-label="Close Modal">×</button>
        <h2 className='more-games-title'>More Games</h2>
        <div className="game-grid">
          {games.map((game, index) => (
            <a key={index} href={game.url} target="_blank" rel="noopener noreferrer" className="game-card">
              <img src={game.logo} alt={game.title} className="game-logo" />
              <span className={`${isDarkMode ? 'dark' : 'light'} game-title`}>{game.title}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreGamesModal;
